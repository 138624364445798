<template>
<div>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Profile">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Person Name"
                  class="required"
                >
                  <b-form-input
                    id="person_name"
                    placeholder="3 to 50 characters"
                    v-model="form.person_name"
                    
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">

                <b-form-group
                  label="Gender"
                  class="required mb-0"
                >


                  <div class="demo-inline-spacing">
                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="male"
                      v-model="form.gender"
                    >
                      Male
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="female"
                      v-model="form.gender"
                    >
                      Female
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>


            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Phone Number"
                  class="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SmartphoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-mobile"
                      type="text"
                      placeholder="Mobile"
                      v-model="form.phone_number"
                      
                    />
                  </b-input-group>
                
                </b-form-group>
              </b-col>
              <b-col md="6">
                      <b-form-group
                        label="Email"
                        :class="required"
                      >
                        
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                           
                            type="text"
                            placeholder=""
                            v-model="form.email"
                            
                          />
                        </b-input-group>
                          
                      </b-form-group>
                    </b-col>
            </b-row>

            
            

            <b-row class="mb-1 mt-1">

              <b-col md="12">
                <h4> Organization Detail</h4>
              </b-col>

              
            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Organization Name"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 50 characters" 
                    v-model="form.organization_name"
                    
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Address"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 100 characters"
                    v-model="form.organization_address"
                  />
                    
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Postal Code"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="6 digits" 
                    v-model="form.organization_postal_code"
                    
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Country"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 50 characters"
                    v-model="form.organization_country"
                  />
                    
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-1 mt-1">

              <b-col md="12">
                <h4> Organization Billing Detail</h4>
              </b-col>

              
            </b-row>

            <b-row>

              <b-col md="12" class="mb-1 mt-1">
                  <b-form-checkbox
                    v-model="form.same_address"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-primary"
                    @change="updateAddress($event)"
                  >
                    Same As Organization Address
                  </b-form-checkbox>
                  
              </b-col>
            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Address"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 100 characters"
                    v-model="form.billing_address"
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Postal Code"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="6 digits" 
                    v-model="form.billing_postal_code"
                    
                  />
                    
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Country"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 50 characters"
                    v-model="form.billing_country"
                  />
                    
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-1 mt-1">

              <b-col md="12">
                <h4> Organization Media Detail</h4>
              </b-col>

              
            </b-row>

            <b-row>
            <b-col md="4">

              <b-form-group
                label="Company Logo"
                class="required full_height_width_img_mobile new_profile_logo logo_bottom_mrgn_mobile"
              >
                <b-media class="mb-2 mt-2 inline_media_mobile mobile_dispaly_inline" @click="$refs.refInputEl.click()">
                  <template #aside>
                    <b-avatar
                      ref="company_logo"
                      :src="form.companyLogo.image != '' ?form.companyLogo.image : doc_icon"
                      variant="light-primary"
                      size="200px"
                      rounded
                    />
                  </template>

                  <div class="d-flex flex-wrap">
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      
                      @input="companyLogoRenderer"
                    >
                  </div>
                </b-media>
              </b-form-group>
            </b-col>

            <!-- <b-col md="4">
              <b-form-group
                label="Company App Logo"
                class="required"
              >
                <b-media class="mb-2 mt-2" @click="$refs.refInputEl2.click()">
                  <template #aside>
                    
                    <b-avatar
                      ref="company_app_logo"
                      :src="form.companyAppLogo.image != '' ?form.companyAppLogo.image : doc_icon"
                      variant="light-danger"
                      size="200px"
                      rounded
                      
                    />

                  </template>
                  <div class="d-flex flex-wrap">
                    <input
                      ref="refInputEl2"
                      type="file"
                      class="d-none"

                      @input="companyAppLogoRenderer"
                    >
                  </div>
                </b-media>
              </b-form-group>
            </b-col> -->
            

          </b-row>

          <b-row>
              <b-col cols="12" class="mb-2 mt-2"> 

                  
                      
                          <span class="mb-0 logo_span"> Signature </span>
                      
                  

                  
                      <b-col cols="12" class="mb-2 mt-0 desktop_pdng_lft_rgt_zero mobile_pdng_lft_rgt_zero full_height_width_img_mobile"> 
                          <b-media class="mb-2 mt-2 inline_media_mobile" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                              
                              <template #aside>
                                  <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                              </template>

                              <div class="d-flex flex-wrap">
                                  <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                              </div>

                          </b-media>

                          <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" class="rounded" style="background: #ffffff">

                          <center>
                              <b-button variant="danger" class="mt-1 ml-2 btn_danger_mobile_left_mrgn_zero" v-if="supervisorSign.image != null" @click="removeSign('supervisor')">
                                  <feather-icon icon="XIcon" />
                              </b-button>
                          </center>
                      </b-col>
                  

              </b-col>
          </b-row>

            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  <b-modal id="crop-modal" ref="crop-modal" centered title="Edit Profile Image" hide-footer 
      :no-close-on-backdrop="true" @hide="resetModal">
      
        <template>
              <div>
                
                <div class="">
                  <section class="cropper-area">

                    <div class="img-cropper">

                     <vue-cropper ref="cropper" :src="temp.image" />
                      
                    </div>

                    <div class="pt-2 text-center float-left">
                      
                      <b-button variant="primary" class="mr-2 text-capitalize" @click.prevent="cropImage">CROP</b-button>

                      <b-button variant="danger" class="mr-2 text-capitalize" @click.prevent="reset">RESET</b-button>

                    </div>
                  </section>
                </div>
              </div>
        </template>
     
    </b-modal>

    <b-modal centered hide-footer
            id="signatureModel"
            ref="signatureModel"
            title="Signature"
            no-close-on-backdrop
            @hide="hideSiganture"
            @show="hideSiganture"
        >
        <b-row>
            <b-col md="12">
                <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="300px"
                    ref="signaturePad"
                    :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3 }"
                />
            </b-col>
        </b-row>

        <b-row class="mt-2">

            <b-col md="3">
                <b-button variant="primary" class="mt-0 w-100" @click="uploadSign()">
                    <span class="text-nowrap">Upload</span>
                </b-button>
            </b-col>

            <b-col md="3">
                <b-button variant="secondary" class="mt-0 w-100" @click="useDefault()">
                    <span class="text-nowrap">Default</span>
                </b-button>
            </b-col>

            <b-col md="3">
                <b-button variant="danger" class="mt-0 w-100" @click="clearSignature">
                    <span class="text-nowrap">Clear</span>
                </b-button>
            </b-col>

            <b-col md="3">
                <b-button variant="warning" class="mt-0" @click="saveSignature">
                <span class="text-nowrap">Submit</span>
                </b-button>
            </b-col>
            
        </b-row>
    </b-modal>

</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Bus from "../../../event-bus";

export default {
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BInputGroupAppend
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      temp:{
        image:null,
        name:'',
      },

      doc_icon: require('@/assets/images/doc.png'),
      error_message:null,
      showDismissibleAlert:false,
      form:{
        id:'',
        client_id:'',
        person_name : '',
        gender : '',
        email : '',
        phone_number : '',
        email:'',
        username:'',
        password:'',
        /*address : '',
        postal_code : '',
        city : '',
        country : '',*/
        organization_name : '',
        organization_address :'',
        organization_postal_code : '',
        organization_country :'',
        /*qi_ratings:'',
        qi_stars:'',*/

        billing_address :'',
        billing_postal_code : '',
        billing_country :'',
        
        same_address:'no',

        company_logo_thumbnail:'',
        companyLogo : {
          image: '',
          name:''
        },
        company_app_logo_thumbnail:'',
        companyAppLogo : {
          image: '',
          name:''
        },
        assign_menus : [],
        custom_forms:[],

      },
      modelbox:'',
      items:null,

      // signature vars
      doc_icon: require('@/assets/images/doc.png'),
      supervisorSign : {
          image:null,
          name:'',
          type: 'supervisor',
          default:'no'
      },
      openedSignModel:null,
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      // assign menus
      this.form.assign_menus = this.items;

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form,
             supervisorSign : this.supervisorSign,
           },
           api: '/api/update-profile'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                Bus.$emit('update-profile');
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.$router.go(-1);
                });
                
            }
        });
      
    },

    showImg(){
      this.$refs['crop-modal'].show();
    },
    resetModal(){
      
      if (this.modelbox == 'companyLogo') {

        this.$refs.refInputEl.value=null;

      }else if(this.modelbox == 'companyAppLogo'){

        this.$refs.refInputEl2.value=null;

      }else if(this.modelbox == 'profileImage'){

        this.$refs.profileInput.value=null;

      }
      

      this.temp.image = null;
      this.temp.name="";

      this.$refs['crop-modal'].hide();
    },
    cropImage() {
      
      if (this.modelbox == 'companyLogo') {

        this.form.companyLogo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.companyLogo.name = this.temp.name;

      }else if(this.modelbox == 'companyAppLogo'){

        this.form.companyAppLogo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.companyAppLogo.name = this.temp.name;

      }else if(this.modelbox == 'profileImage'){

        this.form.profileImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.profileImage.name = this.temp.name;

      }
      
      this.$refs['crop-modal'].hide(); 
      
    },
    reset() {
      this.$refs.cropper.reset();
    },
    companyLogoRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'companyLogo';

                  this.showImg();
            }
          }
      }
    },
    companyAppLogoRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'companyAppLogo';

                  this.showImg();
            }
          }
      }
    },
    /*profileImageRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'profileImage';

                  this.showImg();
            }
          }
      }
    },*/

    clientDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser.organization
           },
           api: '/api/client-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.item = this.$store.getters.getResults.data;
                
                this.form.person_name = this.item.admin.full_name;
                this.form.gender = this.item.admin.gender;
                this.form.email = this.item.admin.email;
                this.form.username = this.item.admin.username;
                this.form.phone_number = this.item.admin.phone_number;
                /*this.form.address = this.item.admin.address;
                this.form.postal_code = this.item.admin.postal_code;
                this.form.city = this.item.admin.city;
                this.form.country = this.item.admin.country;*/
                this.form.organization_name = this.item.organization_name;
                this.form.organization_address = this.item.address;
                this.form.organization_postal_code = this.item.postal_code;
                this.form.organization_country = this.item.country;

                this.form.billing_address = this.item.billing_address;
                this.form.billing_postal_code = this.item.billing_postal_code;
                this.form.billing_country = this.item.billing_country;

                if (this.form.organization_address == this.form.billing_address && this.form.organization_postal_code && this.form.billing_postal_code && this.form.organization_country == this.form.billing_country) {
                  this.form.same_address = 'yes';
                }


                /*this.form.qi_ratings= this.item.qi_ratings;
                this.form.qi_stars= this.item.qi_stars;*/
                this.form.companyLogo.image = this.item.company_logo +'?'+ Date.now();
                this.form.companyAppLogo.image = this.item.company_app_logo +'?'+ Date.now();
                
                this.form.id = this.item.admin._id;
                this.form.client_id = this.item._id;
                this.form.company_logo_thumbnail = this.item.company_logo_thumbnail; 
                this.form.company_app_logo_thumbnail = this.item.company_app_logo_thumbnail;
                
                this.form.assign_menus = this.item.admin.menu_permission;

                this.supervisorSign.image = this.item.admin.default_signature;
                this.supervisorSign.name = 'signature.png';
                this.supervisorSign.default = 'yes';

                this.form.custom_forms = this.item.custom_forms;
                
                // populate checkboxes for menus
                if(this.item.admin.menu_permission.length > 0){
                  this.items = this.item.admin.menu_permission;
                } else{
                  this.getMenus();
                }

                return this.form, this.items;
            }
        });
    },
    updateAddress(e){
      if (e == 'yes') {
        
        this.form.billing_address =this.form.organization_address;
        this.form.billing_postal_code =this.form.organization_postal_code;
        this.form.billing_country =this.form.organization_country;

      }else{

        this.form.billing_address = '';
        this.form.billing_postal_code ='';
        this.form.billing_country ='';

      }
    },

    // added for menu permission 
    getMenus(){
        return this.$store.dispatch(POST_API, {
            data:{
              user_type:'sub_admin'
            },
            api: '/api/get-menus'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.items = this.$store.getters.getResults.data;
                
                return this.items;
            }
        }); 
    },

    selectAllMenu(e){
      if(this.items){
        this.items.forEach(menu => {
          if(menu.permissions){
            this.forParent(menu.permissions, e);
          } else {
            this.forChild(menu.children, e);
          }
          menu.access = e;
        });
      }
    },

    toggleChild(permissions){
      if(permissions.permissions){
        this.forParent(permissions.permissions, permissions.access);
      } else {
        this.forChild(permissions.children, permissions.access);
      }
    },

    forParent(menu, access){
      menu.forEach(function(item){
        item.access = access;
      }); 
    },

    forChild(menu, access){
      menu.forEach(function(item){
        item.access = access;
        item.permissions.forEach(function(inner_item){
          inner_item.access = access;
        }); 
      });
    },

    toggleParent(menu){
      var flag = false;

      if(menu.permissions){
        menu.permissions.forEach(item => {
          if(item.access == true){
            flag = true;
          }
        });
      } else {
        menu.children.forEach(item => {
          if(item.access == true){
            flag = true;
          }
        });
      }
      menu.access = flag;
    },

    toggleParent2(menu,){
      var flag = false;
      menu.children.forEach(item => {
        item.permissions.forEach(elem => {
          if(elem.access == true){
            flag = true;
          }
        });
      });
      menu.access = flag;
    },

    // signature functions
      hideSiganture(){
          this.openedSignModel = null;
      },

      openSignature(type){
          if (this.openedSignModel == null) {
              this.$refs['signatureModel'].show();
              this.openedSignModel = type;
          }
      },

      uploadSign(){                
          if (this.openedSignModel == 'supervisor') {
              this.$refs['supervisorSInput'].click();
          }
          if (this.openedSignModel == 'client') {
              this.$refs['clientSInput'].click();
          }
          this.$refs['signatureModel'].hide();
      },

      clearSignature(){
          this.$refs.signaturePad.clearSignature();
      },

      saveSignature(){
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          if (isEmpty) {
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Signature is required',
                  showConfirmButton: false,
                  timer: 1500
              })
          }else{
              if (this.openedSignModel == 'supervisor') {
                  this.supervisorSign.image = data;
                  this.supervisorSign.name = 'signature.png';
                  this.supervisorSign.type =  'supervisor';
                  this.supervisorSign.default = 'no';
              } else {
                  this.clientSign.image = data;
                  this.clientSign.name = 'signature.png';
              }
              this.$refs['signatureModel'].hide();
          }
      },

      supervisorImageUpload(event){
          var input = event.target;
          var files = event.target.files

          if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var image = input.files[0];

              if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Please upload .png, .jpeg, .jpg images only',
                      showConfirmButton: false,
                      timer: 1500
                  });

                  this.$refs.supervisorSInput.value=null;

              } else if (image.size > 2097152) {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Maximum 2 MB files allowed to be upload.',
                      showConfirmButton: false,
                      timer: 1500
                  })
                  this.$refs.supervisorSInput.value=null;
              } else {
                  reader.onload = (e) => {
                      this.supervisorSign.image = e.target.result;
                      this.supervisorSign.name = image.name;
                      this.supervisorSign.type =  'supervisor';
                      this.supervisorSign.default = 'no';
                  }
              }
          }
      },
          
      useDefault(){
          return this.$store.dispatch(POST_API, {
              data:{
                  id:this.$store.getters.currentUser._id
              },
              api: '/api/get-default-signature'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  if (data == null || data.default_signature == null) {
                      Swal.fire({
                          position: 'center',
                          icon: 'warning',
                          title: 'Default signature not found.',
                          showConfirmButton: false,
                          timer: 1500
                      })
                  } else {

                      if (this.openedSignModel == 'supervisor') {
                          this.supervisorSign = {
                              image : data.default_signature,
                              name : 'siganture.png',
                              type: 'supervisor',
                              default:'yes'
                          }
                      }
                      this.$refs['signatureModel'].hide();
                  }
              }
          });

      },
      removeSign(sign){
          this.supervisorSign.image = null;
          this.supervisorSign.name = '';
          this.supervisorSign.default = 'no';
      },

  },
  mounted(){
    // this.getMenus();
    this.clientDetail();
  }
}
</script>
