<template>
<div>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Profile">
     
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>

          <b-col md="2">

            <b-media class="mb-2" @click="$refs.profileInput.click()">
              <template #aside>
                <b-avatar
                  ref="logo"
                  :src="form.profileImage.image != '' ?form.profileImage.image : ''"
                  variant="light-primary"
                  size="180px"
                  rounded
                  
                />
              </template>
              
              <div class="d-flex flex-wrap">
                <input
                  ref="profileInput"
                  type="file"
                  class="d-none"
                  @input="profileImageRenderer"
                >
              </div>
            </b-media>

            <p class="text-danger" v-if="form.role == 'supervisor' || form.role == 'crew'">(This field is required)</p>

          </b-col>
          

          <b-col md="10">

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Person Name"
                  class="required"
                >
                  <b-form-input
                    id="person_name"
                    placeholder="3 to 50 characters"
                    v-model="form.person_name"
                    
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">

                <b-form-group
                  label="Gender"
                  class="required mb-0"
                >


                  <div class="demo-inline-spacing">
                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="male"
                      v-model="form.gender"
                    >
                      Male
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="female"
                      v-model="form.gender"
                    >
                      Female
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
             
            </b-row>
            
            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Phone Number"
                  :class="isReq"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SmartphoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      
                      type="text"
                      placeholder="Mobile"
                      v-model="form.phone_number"
                      
                    />
                  </b-input-group>
                
                </b-form-group>
              </b-col>
              <b-col md="6">
                      <b-form-group
                        label="Email"
                        :class="required"
                      >
                        
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                           
                            type="text"
                            placeholder=""
                            v-model="form.email"
                            
                          />
                        </b-input-group>
                          
                      </b-form-group>
                    </b-col>
              
              

              
            </b-row>

            <b-row v-if="form.role == 'supervisor' || form.role == 'crew'">
              <b-col md="6" >
                
                <b-form-group
                  label="Job Title"
                  class="required"

                >
                  <b-form-select v-model="form.job_title">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="title._id" v-for="title in job_titles" :key="title._id">{{title.job_title}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              <!-- <b-col md="6" v-if="form.role == 'crew'">
                
                <b-form-group
                  label="Shift"
                  class="required"

                >
                  <b-form-select v-model="form.shift">

                    <b-form-select-option value="">Select</b-form-select-option>
                    
                    <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col> -->

              <b-col md="6" v-if="form.role == 'supervisor' || form.role == 'crew'">
                <b-form-group
                  label="IC/ID Number"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="3 to 50 characters"
                    v-model="form.ic_number"
                    
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <!-- <b-row v-if="form.role == 'supervisor' || form.role == 'crew'">

              <b-col md="6">
                <b-form-group
                  label="IC/ID Number"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="3 to 50 characters"
                    v-model="form.ic_number"
                    
                  />
                </b-form-group>
              </b-col>

              
            </b-row> -->

            <hr>

            <b-row>

              <b-col
                cols="6"
                class="mb-2"
              > 

                <b-row>
                  
                    <b-col
                    cols="12"
                    class="mb-2 mt-2"
                    >
                      <h4 class="mb-0">
                       Signature
                      </h4>
                      
                    </b-col>
                </b-row>

                <b-row>
                    <b-col
                      cols="6"
                      class="mb-2 mt-0"
                    > 
                  
                        <b-media class="mb-2 mt-2" @click="openSignature('supervisor')" v-if="form.signature.image == null">
                          <template #aside>
                            <b-avatar
                              ref="supervisorS"
                              :src="form.signature.image != null ? form.signature.image : doc_icon"
                              variant="light-info"
                              size="200px"
                              rounded
                            />
                          </template>

                          <div class="d-flex flex-wrap">
                            <input
                              ref="supervisorSInput"
                              type="file"
                              class="d-none"
                              @input="supervisorImageUpload"
                            >
                          </div>

                          
                        </b-media>

                        <!-- <b-media class="mb-2 mt-2" v-if="form.signature.image != null" v-viewer>
                          <template #aside>
                            <b-avatar
                              ref="supervisorS"
                              :src="form.signature.image != null ? form.signature.image : doc_icon"
                              variant="light-primary"
                              size="200px"
                              rounded
                            />
                          </template>

                          <viewer :options="{title: false}"></viewer>

                          
                        </b-media> -->
                        <img :src="form.signature.image" width="240px" height="200px" v-if="form.signature.image != null" style="background: #ffffff; border-radius: 4px" class="mt-1">

                        <center>
                        
                        <b-button
                            variant="danger"
                            class="mt-1 ml-2 mr-0"
                            v-if="form.signature.image != null"
                            @click="removeSign('supervisor')"
                          >
                          <feather-icon icon="XIcon" />
                          
                        </b-button>
                      </center>
                      </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  <b-modal id="crop-modal" ref="crop-modal" centered title="Profile Image" hide-footer :no-close-on-backdrop="true" @hide="resetModal">
      
    <template>
          <div>
            
            <div class="">
              <section class="cropper-area">

                <div class="img-cropper">

                 <vue-cropper ref="cropper" :src="temp.image" />
                  
                </div>

                <div class="pt-2 text-center float-left">
                  
                  <b-button variant="primary" class="mr-2 text-capitalize" @click.prevent="cropImage">CROP</b-button>

                  <b-button variant="danger" class="mr-2 text-capitalize" @click.prevent="reset">RESET</b-button>

                </div>
              </section>
            </div>
          </div>
    </template>
 
  </b-modal>

  <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSiganture"
        @show="hideSiganture"
        
      >
        <b-row>
          <b-col md="12">

            <!-- <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3,backgroundColor: 'rgba(255, 255, 255, 1)'}"
            /> -->
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="4">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload Image</span>
            </b-button>
          </b-col>


          <b-col md="4">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="4">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
          

        </b-row>
      </b-modal>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import vSelect from 'vue-select'
import 'cropperjs/dist/cropper.css';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Bus from "../../../event-bus";

export default {
  name:'ClientEdit',
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    vSelect,
    BInputGroupAppend
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isReq:'',
      selectAll:false,
      doc_icon: require('@/assets/images/doc.png'),
      error_message:null,
      showDismissibleAlert:false,
      form:{
        
        advanced_settings:'no',
        work_schedule:'',
        off_day:[],
        work_day:'',
        position:'',
        ot_rate:'',
        company_name:'',

        main:'no',
        id:'',
        person_name : '',
        gender : '',
        email : '',
        phone_number : '',
        username:'',
        password:'',
        site_id:'',
        role:'',
        job_title:'',
        shift:'',
        ic_number:'',
        assign_menus : [],
        om_sites:[],
        profileImage: {
          image:null,
          name:''
        },
        thumbnail:'',
        signature:{
          image:null,
          name:''
        },
        supervisor_sites : [
          {
            site:'',
            shift:'',
            allshifts:[]
          }
        ],
        custom_forms:[],
        show_custom_forms:false,
        show_custom_forms_reports:false,
      },
      sites:[],
      job_titles:[],
      shifts:[],
      temp:{
        image:null,
        name:'',
      },
      modelbox:'',
      roles:[],

      items:null,
      tableColumns:[

        { key: 'checkbox', label: '', sortable: false , thStyle:  {width: '30%'}},
        { key: 'title', label: 'Menu Name', sortable: false , thStyle:  {width: '70%'}}

      ]
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      // assign menus
      this.form.assign_menus = this.items;

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-client-user'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Bus.$emit('update-profile');

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.$router.go(-1);
                });
                
            }
        });
      
    },
    showImg(){
      this.$refs['crop-modal'].show();
    },
    resetModal(){
      
      if (this.modelbox == 'profile') {

        this.$refs.profileInput.value=null;

      }else{
        this.$refs.refInputEl.value=null;
      }
      

      this.temp.image = null;
      this.temp.name="";

      this.$refs['crop-modal'].hide();
    },
    cropImage() {
      
      if (this.modelbox == 'profile') {

        this.form.profileImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.profileImage.name = this.temp.name;

      }else{
        this.form.document.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.document.name = this.temp.name;
      }

      this.$refs['crop-modal'].hide(); 
      
    },
    reset() {
      this.$refs.cropper.reset();
    },
    
    profileImageRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.profileInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.profileInput.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'profile';
                  this.showImg();
            }
          }
      }
    },

    

    siteList(){
      
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/client-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.sites = this.$store.getters.getResults.data;
                return this.sites;
            }
        });
      
    },
    userDetail(){
      
      return this.$store.dispatch(POST_API, {
           data:{
             id: this.$store.getters.currentUser._id
           },
           api: '/api/client-user-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var item = this.$store.getters.getResults.data;
                
                this.form.site_id = item.site;
                this.getShift();

                this.form.id = item._id;
                this.form.person_name  = item.full_name;
                this.form.gender  = item.gender;
                this.form.email  = item.email;
                this.form.phone_number  = item.phone_number;
                
                this.form.username = item.username;  
                this.form.role = item.role;

                this.form.job_title = item.job_title;
                this.form.shift = item.shift;
                this.form.ic_number = item.ic_number;
                this.form.main = item.main_supervisor;
                this.form.om_sites = item.om_sites;

                this.form.assign_menus = item.menu_permission;
                // populate checkboxes for menus
                this.items = item.menu_permission;

                if (this.form.role == '' || this.form.role == 'crew') {
                  this.isReq = '';
                }else{
                  this.isReq = 'required';
                }

                this.form.profileImage.image = item.profile_image + '?'+ new Date();
                this.form.thumbnail = item.profile_thumbnail;

                this.form.signature.image = item.default_signature ? item.default_signature + '?'+ new Date():null;
                this.form.supervisor_sites = item.supervisor_sites;

                this.form.advanced_settings = item.advanced_settings;
                this.form.work_schedule = item.work_schedule ? item.work_schedule : '';
                this.form.off_day = item.off_day;
                this.form.work_day = item.work_day ? item.work_day : '';
                this.form.position = item.position;
                this.form.ot_rate = item.ot_rate ? item.ot_rate : '';
                this.form.company_name = item.company_name;
                
                if (item.custom_forms.length > 0) {
                  
                  this.form.show_custom_forms = true;
                  
                  this.form.show_custom_forms_reports = true;

                  this.form.custom_forms = item.custom_forms;

                  
                }
                // this.$refs.refUserListTable.refresh();


                return this.form;
            }
        });
      
    },
    dropDownData(){
      return this.$store.dispatch(POST_API, {
           data:{
             
           },
           api: '/api/client-user-dropdown'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                this.job_titles = data.job_titles;
                //this.shifts = data.shifts;
                return this.job_titles;
            }
        });
    },
    updateRole(){

      if (this.form.role == '' || this.form.role == 'crew') {
        this.isReq = '';
      }else{
        this.isReq = 'required';
      }

      this.form.site_id = '';
      this.form.job_title = '';
      this.form.shift = '';
      this.form.ic_number = '';
      this.form.main = '';
      // this.form.assign_menus = [];
      this.form.om_sites = [];
      this.selectAll = false;
      // this.$refs.refUserListTable.refresh();
    },
    getShift(){
      
      this.form.shift = '';

      return this.$store.dispatch(POST_API, {
           data:{
             site: this.form.site_id
           },
           api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                this.shifts = data;
                return this.shifts;
            }
        });
    },
    getMenus(){
        return this.$store.dispatch(POST_API, {
           data:{
             user_type:this.form.role
           },
           api: '/api/get-menus'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.items = this.$store.getters.getResults.data;
                
                return this.items;
            }
        }); 
    },
    selectall(e){

      this.form.assign_menus = [];
      
      if (e) {
        var selected = [];

        this.items.forEach(function(item){
            selected.push(item._id);
        })

        this.form.assign_menus = selected;

      }

    },
    hideSiganture(){
      this.openedSignModel = null;
    },

    openSignature(type){

        if (this.openedSignModel == null) {

            this.$refs['signatureModel'].show();
            this.openedSignModel = type;
        }
      
    },

    uploadSign(){
      
      if (this.openedSignModel == 'supervisor') {

         this.$refs['supervisorSInput'].click();
      }

      if (this.openedSignModel == 'client') {

         this.$refs['clientSInput'].click();
      }

      this.$refs['signatureModel'].hide();

    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{

        this.form.signature = {
          image : data,
          name : 'siganture.png',
        }
          
        this.$refs['signatureModel'].hide();
      }
    },
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                /*this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;*/
                this.form.signature = {
                  image : e.target.result,
                  name : image.name
                }
                

            }
          }
      }
      

    },
    removeSign(sign){
      this.form.signature.image = null;
      this.form.name='';
      this.$refs.supervisorSInput.value=null;
    },

    selectAllMenu(e){
      if(this.items){
        this.items.forEach(menu => {
          if(menu.permissions){
            this.forParent(menu.permissions, e);
          } else {
            this.forChild(menu.children, e);
          }
          menu.access = e;
        });
      }
    },

    toggleChild(permissions){
      if(permissions.permissions){
        this.forParent(permissions.permissions, permissions.access);
      } else {
        this.forChild(permissions.children, permissions.access);
      }
    },

    forParent(menu, access){
      menu.forEach(function(item){
        item.access = access;
      }); 
    },

    forChild(menu, access){
      menu.forEach(function(item){
        item.access = access;
        item.permissions.forEach(function(inner_item){
          inner_item.access = access;
        }); 
      });
    },

    toggleParent(menu){
      var flag = false;

      if(menu.permissions){
        menu.permissions.forEach(item => {
          if(item.access == true){
            flag = true;
          }
        });
      } else {
        menu.children.forEach(item => {
          if(item.access == true){
            flag = true;
          }
        });
      }
      menu.access = flag;
    },

    toggleParent2(menu){
      var flag = false;
      menu.children.forEach(item => {
        item.permissions.forEach(elem => {
          if(elem.access == true){
            flag = true;
          }
        });
      });
      menu.access = flag;
    },
    getRoles(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role
           },
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.roles = this.$store.getters.getResults.data;
                
                return this.roles;
            }
        }); 
    }

  },
  mounted(){
    this.siteList();
    this.userDetail();
    this.dropDownData();
    this.getRoles();
  }
}
</script>
