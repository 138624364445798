<template>
    <div>
        <AdminEdit v-if="this.$store.getters.currentUser.role == 'administrator'" />
        <ClientEdit v-else />
    </div>
</template>
<script>
import AdminEdit from './AdminEdit.vue';
import ClientEdit from './ClientEdit.vue';
export default {
    components: { AdminEdit, ClientEdit },
}
</script>
